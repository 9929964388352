import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common'; 
import * as moment from 'moment';
import { ConfigService } from 'src/app/services/config.service';

import { ApiCallsService } from '../../../services/apicalls.service';
import { UserService } from '../../../services/user.service';
import { Observable, Subject, takeUntil } from 'rxjs';

declare global {
    interface Window {
      fcWidget: any;
    }
  }
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    providers: [ UserService, ApiCallsService ]
})
export class FooterComponent implements OnInit, OnDestroy {

    private unsubscribeAll = new Subject<void>();
    public user: any;
    public user$!: Observable<any>;
    
    public checkout: any;
    public checkout$!: Observable<any>;

    checkoutPage: boolean = false;
    showFooter: boolean = false;
    config: any;
    countdown: any;
    countDownNumber: any;
    constructor(
        private _api: ApiCallsService,
        private _userService: UserService,
        private _router: Router,
        private configService: ConfigService,
        private _location: Location
    ) {
        _location.onUrlChange((url, state) => {
            this.getUser();
            this.getCheckout();
            this.avisoCheckout();
            this.config = this.configService.getConfig();
        });
    }
    
    ngOnInit() {
        this.getUser();
        this.getCheckout();
        this.avisoCheckout();
        this.config = this.configService.getConfig();
    }

    ngOnDestroy() {
        clearInterval(this.countdown);
        this.countdown = '';
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    getUser() {
        this.unsubscribeAll = new Subject<void>();
        this.user$ = this._userService.user$.pipe(
            takeUntil(this.unsubscribeAll)
        );

        this._userService.fetchUserData();
        this.user$.subscribe({
            next: (user: any) => {
                this.user = user || null;
                if(this.user && this.user.id){  
                    window.fcWidget.on("widget:loaded", () => {
                        if (this.user && this.user.id) {
                          window.fcWidget.setExternalId(String(this.user.id));
                          window.fcWidget.user.setFirstName(this.user.name + " " + this.user.surnames);
                          window.fcWidget.user.setEmail(this.user.email);
                        } else {
                          console.error("User information is incomplete or invalid.");
                        }
                      });
                }
            },
            error: (error: any) => {
                console.error('User data fetch error:', error);
            }
        });
    }

    getCheckout() {
        var localstorage: any = localStorage.getItem('checkout');
        this.checkout = JSON.parse(localstorage);
        if(!this.checkout) {
            clearInterval(this.countdown);
            this.countdown = '';
        }
    }

    avisoCheckout(){

        if(this._router.url.includes('checkout') || this._router.url.includes('entrada')) {
            this.checkoutPage = true;
        } else {
            this.checkoutPage = false;
        }
        if(this._router.url.includes('queue')){
            this.showFooter = false;
        }else{
            this.showFooter = true;
        }

        if(this.checkout){
            if(!this.countdown) {
                this.countdown = setInterval(() => {
                    if(this.checkout) {
                        if (moment(this.checkout.moment).isAfter(moment())) {
                            const diffMilliseconds = moment(this.checkout.moment).diff(moment(), 'milliseconds');
                            const totalMinutes = Math.floor(diffMilliseconds / 60000);
                            const remainingSeconds = Math.floor((diffMilliseconds % 60000) / 1000);
                            this.countDownNumber = `${totalMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
                        } else {
                            clearInterval(this.countdown);
                            this.countdown = '';
                            this.countDownNumber = '00:00';
                            localStorage.removeItem('checkout');
                            this._api.checkout = null;
                            this.checkout = null;
                        }
                    } else {
                        clearInterval(this.countdown);
                        this.countdown = '';
                    }
                }, 1000);
            }
        } 
    }

}