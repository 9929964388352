<header class="header" *ngIf="viewHeader">
    <div class="main-wrapper">
        <div class="header--content">
            <a [routerLink]="'/'" class="logo">
                <span *ngIf="!config?.landing_promoter">
                    <svg width="266" height="68" viewBox="0 0 266 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M82.3592 36.7363L89.3334 30.6031H96.9012L86.5141 40.2267L99.4733 55.5348H91.9055L82.4087 43.8169L79.985 45.961L80.0839 55.4849H74V19.334H80.0839V35.6892C80.0839 37.5341 79.0947 39.6284 78.3033 41.3237L79.342 41.7725C80.0839 40.0772 80.9742 37.933 82.3592 36.7363Z" fill="#F77367"/>
                        <path d="M102.849 49.4515V19.334H108.933V47.1079C108.933 49.1523 109.774 49.8005 112.049 49.8005H115.115V55.4849H111.455C105.817 55.4849 102.849 53.3408 102.849 49.4515Z" fill="#F77367"/>
                        <path d="M138.688 52.9918C136.857 55.0362 134.285 56.2828 130.971 56.2828C123.601 56.2828 118.408 50.2992 118.408 42.8695C118.408 35.4399 123.601 29.8053 130.971 29.8053C134.285 29.8053 136.857 30.9522 138.638 32.8968C139.677 33.9938 139.875 36.0382 140.369 37.4843L141.408 37.1352C140.963 35.6892 140.073 33.944 140.073 32.4481L140.023 30.5532H146.107V55.4849H140.023L140.073 53.4405C140.073 51.9446 140.963 50.1496 141.408 48.7534L140.32 48.4043C139.875 49.8005 139.677 51.8449 138.688 52.9918ZM124.492 42.8695C124.492 47.058 127.212 50.5983 131.318 50.5983C135.374 50.5983 138.094 46.9583 138.242 42.8695C138.242 38.4815 135.819 35.4897 131.318 35.4897C127.212 35.4897 124.492 38.6311 124.492 42.8695Z" fill="#F77367"/>
                        <path d="M158.677 53.5403V67.9508L152.593 68.0006V30.6031L158.677 30.5532V32.3483C158.677 33.8442 157.836 35.5894 157.342 37.0355L158.38 37.3845C158.875 35.9385 159.122 33.8941 160.111 32.847C161.942 30.9023 164.514 29.7554 167.828 29.7554C175.247 29.7554 180.342 35.5396 180.193 42.9194C179.995 50.0997 175 56.2329 167.828 56.2329C164.563 56.2329 161.991 54.9364 160.161 52.9918C159.122 51.9446 158.875 49.9002 158.38 48.5041L157.342 48.8531C157.787 50.2991 158.677 52.0444 158.677 53.5403ZM166.987 35.4399C162.387 35.4399 159.765 38.6311 159.765 42.8196C159.765 47.058 162.387 50.5485 166.987 50.5485C171.636 50.5485 174.109 47.058 174.109 42.8196C174.109 38.6311 171.636 35.4399 166.987 35.4399Z" fill="#F77367"/>
                        <path d="M190.316 53.5403V67.9508L184.232 68.0006V30.6031L190.316 30.5532V32.3483C190.316 33.8442 189.475 35.5894 188.98 37.0355L190.019 37.3845C190.514 35.9385 190.761 33.8941 191.75 32.847C193.58 30.9023 196.152 29.7554 199.466 29.7554C206.886 29.7554 211.98 35.5396 211.832 42.9194C211.634 50.0997 206.639 56.2329 199.466 56.2329C196.202 56.2329 193.63 54.9364 191.8 52.9918C190.761 51.9446 190.514 49.9002 190.019 48.5041L188.98 48.8531C189.425 50.2991 190.316 52.0444 190.316 53.5403ZM198.626 35.4399C194.026 35.4399 191.404 38.6311 191.404 42.8196C191.404 47.058 194.026 50.5485 198.626 50.5485C203.275 50.5485 205.748 47.058 205.748 42.8196C205.748 38.6311 203.275 35.4399 198.626 35.4399Z" fill="#F77367"/>
                        <path d="M229.819 56.2828C219.778 56.2828 214.387 50.2992 214.387 42.8695C214.387 35.4399 220.273 29.8053 228.632 29.8053C237.239 29.8053 242.581 35.8886 242.531 42.7698C242.531 43.2684 242.531 44.4651 242.482 44.9139L221.658 44.864C221.658 47.6065 224.131 51.0471 229.226 51.0471C232.54 51.0471 234.568 49.6011 235.062 48.3545H241.987C241.245 50.9474 237.585 56.2828 229.819 56.2828ZM221.658 40.7752H235.458C235.458 37.3347 232.935 34.642 228.78 34.642C224.576 34.642 221.658 37.6338 221.658 40.7752Z" fill="#F77367"/>
                        <path d="M255.144 32.2985C257.766 30.2541 260.486 29.8053 265.333 29.8053V35.4897C259.2 35.4897 252.819 37.6837 252.819 44.6147V55.4849H246.785V30.5532H252.819V31.3012C252.819 33.196 251.781 35.4897 251.039 37.2349L252.077 37.6837C252.819 35.9385 253.611 33.4453 255.144 32.2985Z" fill="#F77367"/>
                        <path d="M29.8094 5.649C30.1443 6.58171 30.8657 7.46722 31.9085 7.49195C32.5349 7.50693 33.0638 7.32788 33.4953 6.9548C34.7569 5.86551 35.2693 4.47581 35.0326 2.78569C35.022 2.70903 35.0347 2.63102 35.0693 2.56199C35.1038 2.49296 35.1584 2.43616 35.2259 2.39912L39.5568 0.00778688C39.5656 0.00303581 39.5754 0.000384493 39.5854 3.87827e-05C39.5954 -0.000306927 39.6054 0.00166244 39.6145 0.00579375C39.6237 0.00992507 39.6317 0.0161069 39.6381 0.0238588C39.6444 0.0316107 39.6489 0.0407239 39.6512 0.0504894C40.5202 4.09748 41.4731 8.64716 42.51 13.6995C42.8291 15.2593 43.1625 16.3254 43.5101 16.8977C44.5462 18.6058 46.3105 19.8577 48.3243 20.0779C48.4891 20.0959 49.1244 20.1049 50.2301 20.1049C54.7985 20.1049 59.3557 20.1049 63.9016 20.1049C63.9198 20.1051 63.9376 20.1103 63.953 20.1199C63.9684 20.1296 63.9809 20.1433 63.989 20.1596C63.9971 20.1758 64.0006 20.194 63.9991 20.2121C63.9975 20.2302 63.9911 20.2476 63.9803 20.2622C63.354 21.1253 62.7487 22.016 62.1643 22.9345C61.9598 23.2559 61.7441 23.4829 61.3395 23.5278C59.2613 23.7601 57.5532 24.6418 56.2152 26.1731C55.5612 26.9215 55.0061 28.306 55.9927 29.0207C56.4497 29.3518 56.9824 29.5324 57.5907 29.5624C57.6031 29.5625 57.6152 29.5661 57.6258 29.5727C57.6363 29.5793 57.6449 29.5887 57.6506 29.5999C57.6563 29.6111 57.659 29.6238 57.6583 29.6365C57.6577 29.6493 57.6537 29.6617 57.6469 29.6725L55.4174 32.9426C55.3773 33.002 55.3234 33.0507 55.2603 33.0844C55.1972 33.1181 55.1269 33.1358 55.0555 33.1359C48.8839 33.1449 42.6875 33.1419 36.4665 33.1269C33.6032 33.1201 31.068 31.3918 29.688 28.942C29.3 28.2528 28.9059 26.9223 28.5058 24.9505C27.3746 19.3737 26.2089 13.8344 25.0087 8.33251C24.9952 8.2695 25.0021 8.20394 25.0284 8.14561C25.0547 8.08728 25.0991 8.03932 25.1548 8.00887L29.5667 5.55685C29.6835 5.49093 29.7644 5.52164 29.8094 5.649Z" fill="#F77367"/>
                        <path d="M41.571 50.2596C42.1104 50.6687 42.87 50.8148 43.542 50.8372C43.5681 50.8381 43.5934 50.8459 43.6154 50.8598C43.6374 50.8737 43.6553 50.8932 43.6672 50.9164C43.6791 50.9395 43.6846 50.9655 43.6832 50.9914C43.6817 51.0174 43.6733 51.0426 43.6589 51.0642L40.7124 55.4514C40.6661 55.5199 40.6038 55.576 40.5309 55.6148C40.458 55.6536 40.3768 55.6739 40.2944 55.6739C31.9083 55.6798 23.5513 55.6791 15.2236 55.6716C11.3219 55.6671 7.75966 53.0241 6.06505 49.6348C5.68448 48.8752 5.29641 47.5372 4.90085 45.6208C3.3276 37.9988 1.69592 30.2487 0.00580406 22.3705C-0.00591807 22.3161 0.000216034 22.2594 0.0232957 22.2088C0.0463754 22.1582 0.085169 22.1163 0.133911 22.0895L6.17068 18.734C6.1975 18.7191 6.22736 18.7104 6.25806 18.7087C6.28875 18.707 6.31948 18.7123 6.34798 18.7241C6.37648 18.736 6.402 18.7542 6.42267 18.7773C6.44334 18.8004 6.45861 18.8279 6.46735 18.8576C7.2742 21.6603 10.2993 22.2266 12.1018 19.9701C13.2735 18.5018 13.7185 16.8207 13.4368 14.9268C13.4275 14.8627 13.4378 14.7973 13.4662 14.7391C13.4947 14.681 13.5401 14.6328 13.5964 14.6009L19.332 11.4117C19.4968 11.3188 19.5995 11.3652 19.6399 11.551C20.8521 17.1862 22.0455 22.8717 23.2202 28.6073C23.6832 30.8638 24.0435 32.3089 24.3012 32.9427C25.371 35.5678 27.9557 37.7029 30.8257 38.1569C31.2273 38.2198 32.2933 38.2513 34.0239 38.2513C39.9708 38.2498 45.9708 38.2505 52.0241 38.2535C52.0469 38.2536 52.0693 38.2597 52.089 38.2714C52.1086 38.283 52.1247 38.2996 52.1355 38.3195C52.1464 38.3393 52.1516 38.3617 52.1506 38.3841C52.1495 38.4065 52.1423 38.4282 52.1297 38.4468C51.2202 39.8268 50.334 41.152 49.4709 42.4226C49.3406 42.6144 49.1593 42.723 48.927 42.7485C46.0742 43.0512 43.7256 44.2116 41.8811 46.2299C40.881 47.3267 40.1101 49.1516 41.571 50.2596Z" fill="#F77367"/>
                    </svg>                        
                </span>
                <img *ngIf="config?.landing_promoter" src="https://euroclub.es/img/logo_azul.svg" alt="">
            </a>
            <div class="icons">
                <a (click)="showSearch()" class="icon icon_search">
                    <span>
                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.1932 21.2167L29 29M24.5 13.25C24.5 19.4631 19.4631 24.5 13.25 24.5C7.03679 24.5 2 19.4631 2 13.25C2 7.03679 7.03679 2 13.25 2C19.4631 2 24.5 7.03679 24.5 13.25Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            
                    </span>
                </a>
                <a [routerLink]="'/login'" *ngIf="!user" class="icon icon_user">
                    <span>
                        <svg width="20" height="27" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="Vector" d="M19.5138 13.9921C19.1897 13.6639 18.7963 13.5 18.3332 13.5H5.55541V7.87509C5.55541 6.6327 5.98953 5.57229 6.85764 4.69334C7.72583 3.81456 8.77321 3.37508 10.0002 3.37508C11.227 3.37508 12.2745 3.81451 13.1423 4.69334C14.0105 5.57229 14.4446 6.63275 14.4446 7.87509C14.4446 8.17968 14.5545 8.44332 14.7742 8.66595C14.9944 8.88864 15.2549 9.00003 15.5554 9.00003H16.6671C16.9678 9.00003 17.2281 8.88864 17.4483 8.66595C17.6676 8.44332 17.778 8.17968 17.778 7.87509C17.778 5.70689 17.017 3.85273 15.4951 2.3116C13.9731 0.770411 12.1413 0 10.0002 0C7.85882 0 6.02723 0.770411 4.50505 2.31154C2.98313 3.8525 2.22215 5.70682 2.22215 7.87504V13.5H1.66666C1.20384 13.5 0.810223 13.6642 0.486109 13.992C0.161996 14.3199 0 14.7185 0 15.1873V25.3125C0 25.7815 0.162057 26.1799 0.486109 26.5079C0.810223 26.8358 1.20384 27 1.66666 27H18.3332C18.7963 27 19.1902 26.8358 19.5138 26.5079C19.8377 26.1799 20 25.7815 20 25.3125V15.1874C20.0002 14.7186 19.8382 14.3204 19.5138 13.9921Z" fill="white"/>
                            </svg>
                            
                    </span>
                </a>
                <a [routerLink]="'/mi-cuenta'" *ngIf="user?.avatar_text" class="icon-account">{{ user?.avatar_text }}</a>
            </div>

            <form [formGroup]="formResultados" class="formulario-busqueda" [ngClass]="{'active': showSearchForm}">
                <div class="fondo" (click)="showSearchForm = false"></div>
                <div class="formulario-busqueda--content">
                    <div class="search--content">
                        <div class="input">
                            <div class="icon-search">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector" d="M12.3738 12.3876L17 17M14.3333 7.66667C14.3333 11.3485 11.3485 14.3333 7.66667 14.3333C3.98476 14.3333 1 11.3485 1 7.66667C1 3.98476 3.98476 1 7.66667 1C11.3485 1 14.3333 3.98476 14.3333 7.66667Z" stroke="#082A45" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    
                            </div>
                            <input type="text" formControlName="busqueda" placeholder="Encuentra un evento" (input)="onInputSearch($event)" (focus)="focusFunction()" (focusout)="focusOutFunction()">
                            <div class="icon-cross" *ngIf="formResultados.value.busqueda" (click)="resetFormResultados()" >
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Group 37">
                                    <line id="Line 1" x1="1.36997" y1="0.663664" x2="11.37" y2="11.6637" stroke="#C5C5C5"/>
                                    <path id="Line 2" d="M11.0117 0.988281L0.499821 11.5002" stroke="#C5C5C5"/>
                                    </g>
                                    </svg>
                                    
                            </div>
                        </div>
                        <div class="results" *ngIf="showResults">
                            <div class="results-title" *ngIf="results.length > 0">
                                <p *ngIf="formResultados.value.busqueda">Resultados de la búsqueda</p>
                            </div>
                            <div class="results-results" *ngIf="results.length > 0">
                                <div class="result" *ngFor="let result of results" (click)="goToEvent(result?.slug)" >
                                    <div class="result--content">
                                        <div class="image--content"><img src="{{ global_url + '/storage/' + result?.image_path }}" alt=""></div>
                                        <div class="informacion">
                                            <div class="title"><p>{{ result?.name }}</p></div>
                                            <p class="capitalize">{{ parseDate(result.start_datetime)}}</p>
                                            <p>{{ result?.venue }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="results-title">
                                <p *ngIf="results.length < 1"> Mmm, no encontramos nada con "{{ formResultados.value.busqueda }}"</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</header>