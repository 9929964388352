import { Component, OnDestroy, OnInit } from '@angular/core';
import {environment} from "../environments/environment";
import { Location } from '@angular/common'; 

import * as AOS from 'aos';

import { Subscription }   from 'rxjs';
import { ConfigService } from './services/config.service';
import { Meta, Title } from '@angular/platform-browser';
import { ApiCallsService } from './services/apicalls.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  production: boolean = false;
  private linkElement: HTMLLinkElement;

  constructor(private configService: ConfigService,  private _api: ApiCallsService, private meta: Meta,  private _location: Location, private title: Title,){
    this.linkElement = document.createElement('link');
    this.linkElement.rel = 'stylesheet';
    this.linkElement.type = 'text/css';
    document.head.appendChild(this.linkElement);
    _location.onUrlChange((url, state) => {
      if (this.isTokenExpiringSoon()) {
          console.log('El token expira en menos de 2 minutos');
          // HAGO REFRESH
          this._api.refreshToken().subscribe((data: any) => {
              localStorage.setItem('accessToken', data.access_token);
              const expirationDate = new Date().getTime() + data.expires_in * 1000;
              localStorage.setItem('accessTokenExpires', expirationDate.toString());
          }, (error: any) => {
              console.log(error);
          });
      }

  });
  }

  ngOnInit() {
    AOS.init();
    if(environment.production){
      this.production = environment.production;
    }
    if (environment.noIndex) {
      const metaTag = document.createElement('meta');
      metaTag.name = 'robots';
      metaTag.content = 'noindex, nofollow';
      document.head.appendChild(metaTag);
    }

    

    //console.log('Config:', this.configService.getConfig());
    
    const config = this.configService.getConfig();
    if (config && config.theme && config.landing_promoter) {
      const themeUrl = `/assets/theme-${config.theme}.css`;
      this.linkElement.href = themeUrl;
      this.title.setTitle(config.title);
      this.meta.addTags([
        { name: 'title', content: 'Compra tus entradas para ' + config.title },
        { name: 'description', content: 'Compra tus entradas para ' + config.title },
        { name: 'robots', content: 'index, follow' },
        { property: 'og:title', content: config.title },
        { property: 'og:description', content: 'Compra tus entradas para ' + config.title },
        { property: 'og:url', content: window.location.href },
      ]);
    }
    

  }

  isTokenExpiringSoon(): boolean {
    const expirationTimestamp = localStorage.getItem('accessTokenExpires');

    if (!expirationTimestamp) {
        // Si no hay fecha de expiración, asumimos que el token no es válido
        return false;
    }

    const now = new Date().getTime();
    const expirationDate = +expirationTimestamp; // Convertir el string a número
    const timeRemaining = expirationDate - now;

    // 2 minutos en milisegundos es 120000 ms
    const twoMinutesInMilliseconds = 2 * 60 * 1000;

    // Si el tiempo restante es menor que 2 minutos, devolvemos true
    return timeRemaining <= twoMinutesInMilliseconds;
}


}
