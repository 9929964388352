import { AfterViewInit, Component, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Component({
    selector: 'general-public',
    templateUrl: './general-public.component.html',
})
export class GeneralPublicComponent {

    fcFrameElement!: HTMLElement;

    constructor(
        private _renderer: Renderer2
    ) {
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event) {
        const yOffset = window.pageYOffset + window.innerHeight;
        let max = document.documentElement.scrollHeight;

        if (yOffset >= max) {
            this._renderer['addClass'](document.querySelector('#fc_frame'), 'desactive');
        } else {
            this._renderer['removeClass'](document.querySelector('#fc_frame'), 'desactive');
        }
    }

}
