import { Injectable } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject, tap, map, of, catchError, switchMap, Subject, throwError, takeUntil } from 'rxjs';
import { AuthUtils } from '../guards/auth.utils';

import { User } from '../models/user';
import { environment } from 'src/environments/environment';

@Injectable() 
export class UserService {

    headers: any;
    private currentUserSubject: BehaviorSubject<any> | undefined;
    private unsubscribeAll = new Subject<void>();

    public identity: string | null | undefined;
    public cliente: string | null | undefined;

    // NEW AUTH
    private _user = new BehaviorSubject<User | null>(null);
    private _authenticated: boolean = false;

    set user(value: User | null) {
        this._user.next(value);
    }
    get user$(): Observable<User | null> {
        return this._user.asObservable().pipe(tap((user: User | null) => {
            if (user) {
                this._authenticated = true;
            }
        }));
    }
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }
    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        public _http: HttpClient,
    ) {
        this.headers = new HttpHeaders().set("Authorization", 'Bearer ' + this.accessToken);
    }

    register(user: any): Observable<any> {
        let options_ : any = {
            body: JSON.stringify(user),
            headers: new HttpHeaders({
                "Accept-Language": "es",
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };

        return this._http.request("post", environment.apiRoot + '/api/auth/register', options_);
    }

    update(user: any): Observable<any> {
        
        let options_ : any = {
            body: JSON.stringify(user),
            headers: new HttpHeaders({
                "Accept-Language": "es",
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": 'Bearer ' + this.accessToken

            })
        };

        return this._http.request("put", environment.apiRoot + '/api/user/' + user.id, options_).pipe(tap((response: any) => {
            if(response.name && response.surnames){
                response.avatar_text = response.name.substring(0, 1) + '' + response.surnames.substring(0, 1);
            }
            this._user.next(response);
        }), map((response: any) => response));
    }

    delete(user: any): Observable<any> {
        

        let options_ : any = {
            headers: new HttpHeaders({
                "Accept-Language": "es",
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": 'Bearer ' + this.accessToken

            })
        };

        return this._http.request("delete", environment.apiRoot + '/api/user/'+user, options_);
    }

    changePassword(user: any): Observable<any> {

        
        let options_ : any = {
            body: JSON.stringify(user),
            headers: new HttpHeaders({
                "Accept-Language": "es",
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": 'Bearer ' + this.accessToken
            })
        };

        return this._http.request("post", environment.apiRoot + '/api/auth/changePassword', options_);
    }

    login(user: any) : Observable<any> {
        let options_ : any = {
            body: JSON.stringify(user),
            headers: new HttpHeaders({
                "Accept-Language": "es",
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };
        return this._http.request("post", environment.apiRoot + '/api/auth/login', options_).pipe(
            switchMap((response: any) => {
                this.accessToken = response.access_token;
                localStorage.setItem('accessToken', this.accessToken);
                const expirationDate = new Date().getTime() + response.expires_in * 1000;
                localStorage.setItem('accessTokenExpires', expirationDate.toString());
                return this.me();
            })
        );
    }

    me(): Observable<any> {
        let options_ : any = {
            headers: new HttpHeaders({
                "Accept-Language": "es",
                "Content-Type": "application/json",
                "Accept": "application/json",
            })
        };
        if(this.accessToken) {
            options_.headers = new HttpHeaders().set("Authorization", 'Bearer ' + this.accessToken);
        }
        
        return this._http.request("get", environment.apiRoot + '/api/auth/me', options_).pipe(
            switchMap((response: any) => {
                this._authenticated = true;
                if(response.name && response.surnames){
                    response.avatar_text = response.name.substring(0, 1) + '' + response.surnames.substring(0, 1);
                }
                this.user = response;
                localStorage.setItem('user', JSON.stringify(response));
                localStorage.setItem('identity', JSON.stringify(response));
                return of(response);
            }), catchError(error => {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('accessTokenExpires');
                localStorage.removeItem('user');
                localStorage.removeItem('identity');
                return throwError(() => new Error('Authentication failed'));
            })
        );
    }

    fetchUserData(): void {
        if(this.accessToken) {
            this.me().subscribe();
        }
    }

    logout(): Observable<any> {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('accessTokenExpires');
        localStorage.removeItem('user');
        localStorage.removeItem('identity');

        this._authenticated = false;
        return of(true);
    }

    reset(email: any) : Observable<any> {
        let options_ : any = {
            headers: new HttpHeaders({
                "Accept-Language": "es",
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };
        return this._http.request("get", environment.apiRoot + '/api/auth/resetPassword/'+email, options_);
    }

    getToken(){
        return localStorage.getItem('token');
    }

    signInUsingToken = (): Observable<any> => {
        this._authenticated = true;
        var userLocalstorage: any = localStorage.getItem('user');
        this.user = JSON.parse(userLocalstorage);
        return of(true);
    }

    check(): Observable<boolean> {
        if (this._authenticated) {
            return of(true);
        }
        if (!this.accessToken) {
            return of(false);
        }
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }
        return this.signInUsingToken();
    }
    
}