import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {environment} from "../../environments/environment";

import { UserService } from './user.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable() 
export class ApiCallsService {

    headers: any;

    private _checkout = new BehaviorSubject<{} | null>(null);
    set checkout(value: {} | null) {
        this._checkout.next(value);
        localStorage.setItem('checkout', JSON.stringify(value));
    }
    get checkout$(): Observable<{} | null> {
        return this._checkout.asObservable();
    }

    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }
    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    constructor(
        private _userService: UserService,
        public _http: HttpClient
    ) {
        this.headers = new HttpHeaders().set("Authorization", 'Bearer ' + this.accessToken);
        const storedCheckout = localStorage.getItem('checkout');
        if (storedCheckout) {
        try {
            this._checkout.next(JSON.parse(storedCheckout));
        } catch (error) {
            console.error('Error parsing stored checkout:', error);
        }
        }
    }

    getAllEvents(page_size: number, page: number, promoter: number | null) {
        if(promoter){
            const body = {
                "promoter_id": promoter
            };
        
            const headers = new HttpHeaders({
                "Accept-Language": "es",
                "Content-Type": "application/json",
                "Accept": "application/json"
            });
        
            const params = new HttpParams()
                .set('page_size', page_size.toString())
                .set('page', page.toString());
        
            // Enviamos los headers y los params en las opciones de la solicitud
            const options_ = {
                headers: headers,
                params: params
            };
        
            return this._http.post(environment.apiRoot + '/api/event', body, options_);
        }else{
            let params = new HttpParams().set('page_size', page_size).set('page', page);
            return this._http.get( environment.apiRoot + '/api/event', {params: params, headers: this.headers});
        }
        
    }
    
    getFilteredEvents(page_size: number, page: number, promoter: number | null, filtrado: any) {
        console.log(filtrado);
        const body: any = {};
        
        if (filtrado.location) {
            body["location"] = filtrado.location;
        }
    
        if (filtrado.precio_inicial !== null && filtrado.precio_inicial !== undefined) {
            body["min_price"] = filtrado.precio_inicial;
        }
    
        if (filtrado.precio_final !== null && filtrado.precio_final !== undefined) {
            body["max_price"] = filtrado.precio_final;
        }
    
        if (filtrado.fecha_inicial) {
            body["from_datetime"] = filtrado.fecha_inicial.startOf('day').utc().format('YYYY-MM-DDTHH:mm:ssZ');
        }
    
        if (filtrado.fecha_final) {
            body["to_datetime"] = filtrado.fecha_final.endOf('day').utc().format('YYYY-MM-DDTHH:mm:ssZ');
        }
    
        if (filtrado.tipos && Array.isArray(filtrado.tipos) && filtrado.tipos.length > 0) {
            body["event_type_ids"] = filtrado.tipos;
        }

        const headers = new HttpHeaders({
            "Accept-Language": "es",
            "Content-Type": "application/json",
            "Accept": "application/json"
        });
    
        const params = new HttpParams()
            .set('page_size', page_size.toString())
            .set('page', page.toString());
    
        // Enviamos los headers y los params en las opciones de la solicitud
        const options_ = {
            headers: headers,
            params: params
        };
    
        return this._http.post(environment.apiRoot + '/api/event', body, options_);
    }
    
    
    
    searchEvents(term: any) {
        term = encodeURI(term);
        return this._http.get( environment.apiRoot + '/api/event/search/'+term, {headers: this.headers});
    }

    getAllEventType() {
        return this._http.get( environment.apiRoot + '/api/eventType', {headers: this.headers});
    }

    getAllLocality() {
        return this._http.get( environment.apiRoot + '/api/locality', {headers: this.headers});
    }

    getAllGenders() {
        return this._http.get( environment.apiRoot + '/api/gender', {headers: this.headers});
    }

    getMyOrders() {
        return this._http.get( environment.apiRoot + '/api/privateArea/myOrders', {headers: this.headers});
    }
    getOrder(identifier: any, hash: any) {
        return this._http.get( environment.apiRoot + '/api/privateArea/order/'+identifier+'/'+hash, {headers: this.headers});
    }
    getEventBySlug(evento: any, access_token?: any) {
        let params = new HttpParams();
    
        if (access_token) {
            params = params.set('access_token', access_token);
        }
    
        return this._http.get(environment.apiRoot + '/api/event/' + evento, { params: params, headers: this.headers, observe: 'response' });
    }
    queueCheck(event_id: any, access_token: any){
        let params = new HttpParams().set('event_id', event_id).set('access_token', access_token);
        return this._http.get( environment.apiRoot + '/api/queue/check', {params: params, headers: this.headers});
    }
    discountCodeCheck(discountCode: any, ticket_id: any, orderID: any){
        let params = new HttpParams().set('ticket_id', ticket_id).set('discountCode', discountCode).set('order_id', orderID);
        return this._http.get( environment.apiRoot + '/api/checkout/checkDiscount', {params: params, headers: this.headers});
    }
    checkoutCreateOrder(checkout: any) {
        let options_ : any = {
            body: JSON.stringify(checkout),
            headers: new HttpHeaders({
                "Accept-Language": "es",
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": 'Bearer ' + this.accessToken
            })
        };
    
        return this._http.request("post", environment.apiRoot + '/api/checkout/createOrder', options_);
    }

    refreshToken() {
        let options_ : any = {
            headers: new HttpHeaders({
                "Accept-Language": "es",
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": 'Bearer ' + this.accessToken
            })
        };
    
        return this._http.request("post", environment.apiRoot + '/api/auth/refresh', options_);
    }

    checkoutUpdateOrder(checkout: any) {
        let options_ : any = {
            body: JSON.stringify(checkout),
            headers: new HttpHeaders({
                "Accept-Language": "es",
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };
    
        return this._http.request("put", environment.apiRoot + '/api/checkout/updateOrder/'+checkout.order_id, options_);
    }

    checkoutSetAttendees(checkout: any) {
        let options_ : any = {
            body: JSON.stringify(checkout),
            headers: new HttpHeaders({
                "Accept-Language": "es",
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };
    
        return this._http.request("put", environment.apiRoot + '/api/checkout/setAttendees/'+checkout.order_id, options_);
    }

}
